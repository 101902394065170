// core
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {server} from "../../../../../REST";

// components
import {siteSetupActions} from "../../../../../bus/siteSetup/actions";
import {
    getQsys,
    getSiteSetup,
    getSymetrix,
    getTesira, 
    getXilica
} from "../../../../../bus/siteSetup/selectors";

//styles
import styles from './DspTable.module.scss';

export const DspTable = ({fields, dspType, setDspType,passEdit, disabled = true}) => {
    const dispatch = useDispatch();

    const qsys = useSelector(getQsys);
    const symetrix = useSelector(getSymetrix);
    const tesira = useSelector(getTesira);
    const xilica = useSelector(getXilica);
    const data = useSelector(getSiteSetup);

    const [siteName, seSiteName] = useState(dspType.SiteName);
    const [dspId, seDspId] = useState(fields.DspID.value);
    const [validation, setValidation] = useState('');
    const [validationDspId, setValidationDspId] = useState('');

    const handleSiteName = (e) => {
        seSiteName(e.target.value);
        dispatch(siteSetupActions.setSiteSetup({...data, SiteName: e.target.value}))
    };
    const handleDspId = (e) => {
        seDspId(e.target.value);
        dispatch(siteSetupActions.setSiteSetup({...data, DspID: e.target.value}))
    };

    const handleDataType = async (dspType) => {
    
        await server.setSiteSetup(({...dspType}))
            .then((response) => {
                if (response.status === 200) {
                    console.log(`Data toggled to ${dspType.SiteName}`)
                }
            })
            .catch(() => {
                console.log('error fetching data')
            }).then(() => passEdit(dspType) );
    };

    const handleDsp = (e) => {
        setDspType(e.target.value.toLowerCase());
        switch (e.target.value.toLowerCase()) {
            case 'qsys':
                handleDataType(qsys)
                dispatch(siteSetupActions.setSiteSetup({...qsys}));
                seSiteName(qsys.SiteName);
                // passEdit(qsys)
                break;
            case 'symetrix':
                handleDataType(symetrix)
                dispatch(siteSetupActions.setSiteSetup({...symetrix}));
                seSiteName(symetrix.SiteName);
                // passEdit(symetrix)
                break;
            case 'tesira':
                handleDataType(tesira)
                dispatch(siteSetupActions.setSiteSetup({...tesira}));
                seSiteName(tesira.SiteName);
                // passEdit(tesira)
                break;
            case 'xilica':
                handleDataType(xilica)
                dispatch(siteSetupActions.setSiteSetup({...xilica}));
                seSiteName(xilica.SiteName);
                // passEdit(xilica)
                break;
            default:
                dispatch(siteSetupActions.setSiteSetup({...qsys}));
                seSiteName(qsys.SiteName);
                break;
        }


    };

    useEffect(() => {
        // seSiteName(data.DspType.name);
        if (data.SiteName === '') {
            setValidation('fill in the field please')
        } else {
            setValidation('');
        }
        if (data.DspID === '') {
            setValidationDspId('fill in the field please')
        } else {
            setValidationDspId('');
        }
    }, [data]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.table}>
                <div className={styles.tableRow}>
                    <span>{fields.siteName.label}</span>
                    <div className={styles.field}>
                        <input
                            type={fields.siteName.type}
                            className={styles.value}
                            value={siteName}
                            placeholder="Enter Site Name"
                            disabled={disabled}
                            onChange={(e) => handleSiteName(e)}/>
                        <div className={styles.validation}>{validation}</div>
                    </div>
                </div>
                <div className={styles.tableRow}>
                    <span>{fields.dsp.label}</span>
                    <div className={styles.select}>
                        <select
                            value={dspType}
                            className={styles.value}
                            disabled={disabled}
                            onChange={(e) => handleDsp(e)}>
                            {fields.dsp.value.map((item, index) => (
                                <option value={fields.dsp.jsVal[index]} key={index}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {dspType === 'bss' && <div className={styles.tableRow}>
                    <span>{fields.DspID.label}</span>
                    <div className={styles.field}>
                        <input
                            type={fields.DspID.type}
                            className={styles.value}
                            value={dspId}
                            disabled={disabled}
                            onChange={(e) => handleDspId(e)}
                        />
                        <div className={styles.validation}>{validationDspId}</div>
                    </div>
                </div>}
            </div>
        </div>)

};