//core
import React, {useEffect} from 'react';

//library
import {useDispatch, useSelector} from 'react-redux';

// components
import {TableStatus} from '../../common'
import {statusActions} from "../../../bus/status/actions";
import {networkActions} from "../../../bus/network/actions";
import {getStatus} from '../../../bus/status/selectors';
import {getNetwork} from "../../../bus/network/selectors";
import styles from "../../common/TableStatus/TableStatus.module.scss";

const EasyMix = [
    'Easy Mix Model',
    'Easy Mix IP',
    'Easy Mix Time'
];

const EasyInfo = [
    'Firmware',
    'Software License',
    'Web version'
];

const DSP = [
    'Selected type',
    'Status',
    'Connection info'
];

let statusDataCache = false;
let networkDataCache = false;

export const Status = () => {
    const dispatch = useDispatch();
    let statusData = useSelector(getStatus);
    let networkData = useSelector(getNetwork);
    let offline = '';

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(statusActions.getStatusAsync());
            dispatch(networkActions.getNetworkAsync());
        }, 1000);
        dispatch(statusActions.getStatusAsync());
        dispatch(networkActions.getNetworkAsync());

        return () => clearInterval(interval);
    }, [dispatch]);

    if(typeof statusData === "undefined"){
        return null;
    }

    if(typeof statusData.offline !== "undefined" || typeof networkData.offline !== "undefined"){
    //if (statusData.offline || networkData.offline) {
        statusData = statusDataCache;
        networkData = networkDataCache;
        offline = ': Offline';
    }

    if(typeof statusData.model === "undefined"){
        return null;
    }

    // Validate IP Address
    if (typeof networkData.myIP !== 'undefined') {
        let ipString = networkData.myIP.toString()
        ipString = ipString.split('.')
        for (let i = 0; i < ipString.length; i++) {
            if(ipString[i] >= 0 && ipString[i] <= 255) {
            
        } else {
            networkData.myIP = 'invalid ip'
            }
        }
    }


    const EasyMixData = [statusData.model, networkData.myIP, statusData.time];

    const EasyInfoData = [
      statusData.FirmwareVersion,
        <a class={styles.bluehref} href='/bsd.txt' target='_blank'>License text</a>,
        process.env.REACT_APP_VERSION];

    const DSPData = [statusData.dspType, statusData.connectionStatus, statusData.connectionInfo];

    statusDataCache = statusData;
    networkDataCache = networkData;
    return (
        <section>
            <h2>Status {offline}</h2>
            <TableStatus title='EasyMix Details' header={EasyMix} data={EasyMixData}/>
            <TableStatus title='DSP Details' header={DSP} data={DSPData}/>
            <TableStatus title='Other Details' header={EasyInfo} data={EasyInfoData}/>
        </section>
    );
};