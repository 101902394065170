// components
import apiBase from "./apiBase";

export const getQsys = () => apiBase.get(`/sitesetup.php?DSP=QSYS`, {timeout: 1000});
export const getSymetrix = () => apiBase.get(`/sitesetup.php?DSP=Symetrix`, {timeout: 1000});
export const getTesira = () => apiBase.get(`/sitesetup.php?DSP=Tesira`, {timeout: 1000});
export const getXilica = () => apiBase.get(`/sitesetup.php?DSP=Xilica`, {timeout: 1000});

export const getStatus = () => apiBase.get(`/status.php`, {timeout: 1000});
export const getNetwork = () => apiBase.get(`/network.php`, {timeout: 1000});
export const setNetwork = (data) => apiBase.post(`/network.php`, data);
export const getEmSetup = () => apiBase.get(`/emsetup.php`, {timeout: 1000});
export const setEmSetup = (data) => apiBase.post(`/emsetup.php`, data);
export const getSiteSetup = () => apiBase.get(`/sitesetup.php`, {timeout: 1000});
export const setSiteSetup = (data) => apiBase.post(`/sitesetup.php`, data);
export const getErrors = () => apiBase.get(`/errors.php`, {timeout: 1000});
export const getFader = () => apiBase.get(`/faders.php`, {timeout: 1000});
export const setFader = (data) => apiBase.post(`/faders.php`, data);
export const getCommand = (data) => apiBase.post(`/command.php`, data);

export const firmware = (data, config) => apiBase.post(`/firmware.php`, data, config);
