// core
import React, {useEffect, useState} from 'react';

// library
import {useDispatch, useSelector} from "react-redux";

// components
import {Input} from "./components/Input/Input";
import {CheckBox} from "./components/CheckBox/CheckBox";
import {Select} from "./components/Select/Select";
import {siteSetupActions} from "../../../../../bus/siteSetup/actions";
import {
    getQsys,
    getSiteSetup,
    getSymetrix,
    getTesira,
    getXilica,
} from "../../../../../bus/siteSetup/selectors";

//styles
import styles from './SiteSetupTable.module.scss';

// image
import ok from '../../../../../assets/img/check.svg'
import warning from '../../../../../assets/img/warning.svg'
import error from '../../../../../assets/img/remove.svg'
import question from '../../../../../assets/img/question.svg'


export const SiteSetupTable = ({keys, array, errors, options, bank, disabled = true, setInputHeaderValidation}) => {
    const dispatch = useDispatch();

    const qsys = useSelector(getQsys);
    const symetrix = useSelector(getSymetrix);
    const tesira = useSelector(getTesira);
    const xilica = useSelector(getXilica);
    const data = useSelector(getSiteSetup);

    const [selectValue, setSelectValue] = useState(array[keys].type);
    const [api, setApi] = useState(data);
    const [type, setType] = useState(array[keys].type);
    const [faderOff, setFaderOff] = useState(array[keys]['faderMinOff'] || "");

    const values = Object.keys(array[keys]);
    const fieldNames = Object.keys(options.fieldNames);
    const fieldList = Object.entries(options.fields);
    const noAndBank = {
        no: [],
        banks: [],
    };
    //console.log(options);
    useEffect(() => {

        // validation
        if (
            array[keys].maxGain !== ' ' &&
            array[keys].updateRate !== ' ' &&
            array[keys].dspMinGain !== ' ' &&
            array[keys].minGain !== ' ' &&
            array[keys].name !== ' ' &&
            (array[keys].names === undefined ? 1 : array[keys].names.find(item => item === ' ') !== ' ') &&
            (array[keys].controlNo === undefined ? 1 : array[keys].controlNo.find(item => item === ' ') !== ' ')
        ) {
            setInputHeaderValidation(true);
        } else {
            setInputHeaderValidation(false);
        }
    }, [array, keys, setInputHeaderValidation]);


    if (errors[keys] === undefined) {
        return false;
    }

    // data for 2 column table
    for (let b = 1; b <= 2 * bank; b++) {
        noAndBank.no.push(b);
        if (b <= bank) {
            noAndBank.banks.push('A');
        } else {
            noAndBank.banks.push('B');
        }
    }

    const handleChangeSelect = (e) => {
        setSelectValue(e.target.value);

        switch (e.target.value.toLowerCase()) {
            case 'qsys':
                setType(qsys.type);
                break;
            case 'symetrix':
                setType(symetrix.type);
                break;
            case 'tesira':
                setType(tesira.type);
                break;
            case 'xilica':
                setType(xilica.type);
                break;
            default:
                setType(qsys.type);
                break;
        }

        const stateCopy = Object.assign({...array[keys].type}, api);
        stateCopy[keys].type = e.target.value;
        setApi(stateCopy);

        dispatch(siteSetupActions.setSiteSetup({...data}))
    };

    const dSPMinGain = (e) => {
        setFaderOff(e.target.checked);
        const stateCopy = Object.assign(api);
        stateCopy[keys]['faderMinOff'] = e.target.checked;
        dispatch(siteSetupActions.setSiteSetup({...api}))
    };


    return (
        <div className={styles.wrapper}>
            <div className={styles.table}>
                <div className={styles.siteSetupButtonsWrapper}>
                    {array[keys].type &&
                    <div className={styles.siteSetupButtons}>
                        Type:
                        <select
                            value={selectValue}
                            disabled={disabled}
                            onChange={(e) => handleChangeSelect(e)}>
                            {options.scaleOptions.map((item, index) => {
                                return (<option value={item}>{item}</option>)
                            })}
                        </select>
                    </div>}
                    {options.minGain &&
                    <div className={styles.siteSetupButtons}>
                        Min Gain:
                        <Input item={array[keys].minGain}
                               keys={keys} value={'minGain'}
                               disabled={disabled}/>
                        (dB)
                    </div>}
                    {options.maxGain &&
                    <div className={styles.siteSetupButtons}>
                        Max Gain:
                        <Input item={array[keys].maxGain}
                               keys={keys}
                               value={'maxGain'} disabled={disabled}/>
                        (dB)
                    </div>}
                    {options.tesiraRate &&
                    <div className={styles.siteSetupButtons}>
                        Rate:
                        <Input item={array[keys].updateRate}
                               keys={keys} value={'updateRate'}
                               disabled={disabled}/>
                    </div>}
                    {options.dspMinGain &&
                    <div className={styles.fader}>
                        <>
                            <p>Fader Off = DSP Min Gain</p>
                            <div className='checkbox'>
                                <input
                                    id='fader'
                                    name='faderMinOff'
                                    type="checkbox"
                                    disabled={disabled}
                                    checked={faderOff}
                                    onChange={(e) => dSPMinGain(e)}/>
                                <label htmlFor='fader'/>
                            </div>
                        </>
                    </div>
                    }
                    {options.dspMinGain &&
                    <div className={styles.siteSetupButtons}>
                        DSP Min Gain:
                        <Input
                            item={array[keys].dspMinGain}
                            keys={keys} value={'dspMinGain'}
                            disabled={disabled}/>
                        (dB)
                    </div>}
                    {options.name &&
                    <div className={styles.siteSetupButtons}>
                        Name:
                        <Input item={array[keys].name} keys={keys}
                               value={'name'} disabled={disabled}/>
                    </div>}
                </div>
                <div className={styles.tableHeader}>
                    <div className={styles.tableCell}>No.</div>
                    {options.hideBank ? null : <div className={styles.tableCell}>Bank</div>}
                    <div className={styles.tableCell}>Link</div>
                    <div className={styles.tableCell}>ON</div>
                    {fieldNames.map((title, index) => (
                        <div className={styles.tableCell}>{options.fieldNames[title]}</div>
                    ))}
                </div>
                <div className={styles.tableBody}>
                    {/* No */}
                    <div className={styles.tableRow}>
                        {(noAndBank.no.map((item, index) =>
                            <div key={index} className={styles.tableCell}>{item}</div>))}
                    </div>
                    {/* Bank */}
                    {options.hideBank !== true
                    && <div className={styles.tableRow}>
                        {(noAndBank.banks.map((bank, index) =>
                            <div key={index} className={styles.tableCell}>{bank}</div>))}
                    </div>}
                    {/* errors */}
                    <div className={styles.tableRow}>
                        {(errors[keys].slice(0, 2 * bank).map((item, index) =>
                            <div key={index} className={styles.tableCell}>
                                <img src={item === 0 ? ok : item === 1 ? error : item === 2 ? warning : question}
                                     alt=""/>
                            </div>))}
                    </div>
                    {/* ON */}
                    <div className={styles.tableRow}>
                        {array[keys].enabled.slice(0, 2 * bank).map((value, index) => {
                            return (
                                <div key={index} className={styles.tableCell}>
                                    <CheckBox
                                        index={index}
                                        keys={keys}
                                        item={value}
                                        value='enabled'
                                        disabled={disabled}/>
                                </div>)
                        })}
                    </div>
                    {/* Options*/}
                    {
                        // Object.keys(options.fields).map((keyName, keyIndex) => {
                        //     array[keys][keyName].slice(0, 2 * bank).map((value, index) => {
                        //         console.log(options.fields[keyName])
                        //         console.log(value)
                        //     })
                            // console.log(array)
                            // console.log(keys)
                            // console.log(keyName)
                            // console.log(bank)
                            // console.log(keyIndex)
                        // })  
                    }
                    {
                    Object.keys(options.fields).map((keyName, keyIndex) => {
                        return (
                            <div key={keyIndex} className={styles.tableRow}>
                                {   
                                    array[keys][keyName].slice(0, 2 * bank).map((value, index) => {
                                    if (options.fields[keyName].includes('string')) {
                                        return (
                                            <div key={index} className={styles.tableCell}>
                                                <Input
                                                    invalid={true}
                                                    index={index}
                                                    item={value}
                                                    keys={keys}
                                                    value={keyName}
                                                    noautosize={true}
                                                    disabled={disabled}/>
                                            </div>)
                                    } else if (options.fields[keyName].includes('number')) {
                                        return (
                                            <div key={index} className={styles.tableCell}>
                                                <Input
                                                    invalid={true}
                                                    index={index}
                                                    item={value}
                                                    keys={keys}
                                                    value={keyName}
                                                    noautosize={true}
                                                    disabled={disabled}/>
                                            </div>)
                                    } else if (options.fields[keyName].includes('checkbox')) {
                                        return (
                                            <div key={index} className={styles.tableCell}>
                                                <CheckBox
                                                    index={index}
                                                    keys={keys}
                                                    item={value}
                                                    value={keyName}
                                                    disabled={disabled}/>
                                            </div>)
                                    } else if (Array.isArray(options.fields[keyName])) {
                                        return (
                                            <div key={index} className={styles.tableCell}>
                                                <Select
                                                    index={index}
                                                    keys={keys}
                                                    item={value}
                                                    value={keyName}
                                                    options={options.fields[keyName]}
                                                    disabled={disabled}/>
                                            </div>)
                                    } else {
                                        
                                            console.log('Doing nothing with ', options.fields[keyName])
                                        
                                        return null
                                    }
                                })}
                            </div>
                        )
                        //console.log(keyName);
                        //console.log(options.fields[keyName]);

                    })}
                    {/*values.map((value, index) => {
                            if (Array.isArray(array[keys][value])) {
                                return (
                                    (value !== 'linkedPairs' && value !== 'updateRate' && value && value !== 'offNames' && value !== 'types_') &&
                                    <div key={index} className={styles.tableRow}>
                                        {(array[keys][value].slice(0, 2 * bank).map((item, index) => {
                                            return (<div key={index} className={styles.tableCell}>
                                                {value === 'enabled' || value === 'readOnly'
                                                    ? <CheckBox
                                                        index={index}
                                                        keys={keys}
                                                        item={item}
                                                        value={value}
                                                        disabled={disabled}/>
                                                    : (value === 'names' || value === 'controlNo' || value === 'controlNo' || value === 'onNames')
                                                        ? <Input
                                                            invalid={true}
                                                            index={index}
                                                            item={item}
                                                            keys={keys}
                                                            value={value}
                                                            disabled={disabled}/>
                                                        : value === 'types'
                                                            ? <Select
                                                                index={index}
                                                                keys={keys}
                                                                item={item}
                                                                value={value}
                                                                disabled={disabled}/>
                                                            : <span>{item}</span>}
                                            </div>)
                                        }))}
                                    </div>

                                )
                            }
                            return null;
                        }
                    )*/}
                </div>


            </div>
        </div>
    );

};