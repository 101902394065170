import {takeEvery, all, call} from 'redux-saga/effects';

import {siteSetupActions} from '../actions';

import {getSiteSetup, getQsys, getSymetrix, getTesira, getXilica} from './workers';

export function* watchGetSiteSetup() {
    yield takeEvery(siteSetupActions.getSiteSetupAsync, getSiteSetup);
}

export function* watchGetQsys() {
    yield takeEvery(siteSetupActions.getQsysAsync, getQsys);
}

export function* watchGetSymetrix() {
    yield takeEvery(siteSetupActions.getSymetrixAsync, getSymetrix);
}

export function* watchGetTesira() {
    yield takeEvery(siteSetupActions.getTesiraAsync, getTesira);
}

export function* watchGetXilica() {
    yield takeEvery(siteSetupActions.getXilicaAsync, getXilica);
}

export function* watchSiteSetup() {
    yield all([
        call(watchGetSiteSetup),
        call(watchGetQsys),
        call(watchGetSymetrix),
        call(watchGetTesira),
        call(watchGetXilica),
    ]);
}
