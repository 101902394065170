// core
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

//components
import {Button, Input} from "../../common";
//import {commandActions} from "../../../bus/command/actions";
import {mysteryActions} from "../../../bus/mystery/actions";
import {getUploadProgress} from "../../../bus/mystery/selectors";
import {server} from '../../../REST'
//styles
import styles from './Update.module.scss';
import {getStatus} from "../../../bus/status/selectors";
//import {networkActions} from "../../../bus/network/actions";
import {statusActions} from "../../../bus/status/actions";

export const Update = () => {
    const dispatch = useDispatch();
    const progress = useSelector(getUploadProgress);
    const statusData = useSelector(getStatus);
    const [inputValue, setInputValue] = useState('');
    const [show, setShow] = useState(false);
    const [disabled, seDisabled] = useState(false);

    const config = {
        onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            dispatch(mysteryActions.setUploadProgress(percentCompleted));
        }
    };

    const upload = async () => {
        if (inputValue.length === 0) {
            alert('Please choose file first.')
        } else {
            await server.firmware(new Blob([inputValue]), config)
        }
    };

    useEffect(() => {
        if (progress === 100 || progress === '') {
            setTimeout(() => {
                setShow(false);
                seDisabled(false)
            }, 1000);
        } else {
            setShow(true);
            seDisabled(true)
        }
    }, [progress]);

    useEffect(() => {
        dispatch(statusActions.getStatusAsync());
    }, [dispatch]);

    return (
        <section className={styles.update}>
            <h2>Update</h2>
            <div className={styles.updateInner}>
                <h3>Firmware Version {statusData.FirmwareVersion}</h3>
                <h3>Build Date {statusData.FirmwareDate} {statusData.FirmwareTime}</h3>
                <h3>Bootloader Version {statusData.BootVersion}</h3>
                <Input setInputValue={setInputValue}/>
                <div className={styles.updateButtons}>
                    {show && <div className={styles.progressBar}><span style={{width: `${progress}%`}}/></div>}
                    <Button text='Upload Firmware' func={() => upload()} disabled={disabled}/>
                </div>
            </div>
        </section>
    );
};