import axios from 'axios';

const { REACT_APP_URL_API_DEV } = process.env;
const { REACT_APP_PHP_PROXY } = process.env;

const apiBase = axios.create({
    baseURL: `${REACT_APP_URL_API_DEV}`,
    // proxy: {host:REACT_APP_PHP_PROXY}
});

export default apiBase;
