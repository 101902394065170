// core
import React from 'react';

// components
import {Input} from './../../components'

// styles
import styles from './../../UtilitiesTable.module.scss';

export const UtilitiesTableEm8 = ({utilities, data, disabled, model}) => {

    const Column = ({row, startRow, endRow}) => {
        return (<div className='tableRowWrapper'>
            <div className={styles.tableRow}>
                {row['Header'].slice(startRow, endRow).map((raw, index) => (
                    <div key={index} className={styles.tableCell}>{raw}</div>))}
            </div>
            <div className={styles.tableRow}>
                {row['Raw value'].slice(startRow, endRow).map((raw, index) => (
                    <div key={index} className={styles.tableCell}>{raw}</div>))}
            </div>
            <div className={styles.tableRow}>
                {row['Upper value'].slice(startRow, endRow).map((upper, index) => (
                  upper === 'Upper value' ? <div key={index} className={styles.tableCell}>{upper}</div> :
                    <div key={index} className={styles.tableCell}>
                        <div key={index} className={styles.inputWrapper}>
                            <Input index={startRow + index}
                                   data={data}
                                   item={upper}
                                   name={'upperCalibration'}
                                   disabled={disabled}/>
                        </div>
                    </div>))}
            </div>
            <div className={styles.tableRow}>
                {row['Center value'].slice(startRow, endRow).map((center, index) => (
                  center === 'Center value' ? <div key={index} className={styles.tableCell}>{center}</div> :
                    <div key={index} className={styles.tableCell}>
                        <div key={index} className={styles.inputWrapper}>
                            <Input index={startRow + index}
                                   data={data}
                                   item={center}
                                   name={'centerCalibration'}
                                   disabled={disabled}/>
                        </div>
                    </div>))}
            </div>
        </div>)
    };

    return (
        <div className={styles.table}>
            {[utilities].map((row, index) => (
                <div key={index} className={`${styles.tableBody} ${model}`}>
                    <Column row={row} startRow={0} endRow={1}/>
                    <Column row={row} startRow={1} endRow={3}/>
                    <Column row={row} startRow={3} endRow={5}/>
                    <Column row={row} startRow={5} endRow={7}/>
                    <Column row={row} startRow={7} endRow={9}/>
                </div>
            ))}

        </div>
    );
};